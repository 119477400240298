.custom-radio {
	$radio-size: 1.25rem;
	min-width: 250px;
	border-radius: 5px;
	cursor: pointer;
	padding: 0.75rem 1.25rem;

	&:hover {
		background-color: rgba(gray, 0.2);
		transition: 0.5s background-color;
	}

	.form-check-input {
		width: $radio-size;
		height: $radio-size;
		border-color: rgba(gray, 0.5);
		margin: 0 1rem 0 0.5rem;
	}

	.form-check-label {
		cursor: pointer;
	}
}
