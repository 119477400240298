.disclaimer-modal {
	display: flex !important;
	align-items: flex-end;
	justify-content: center;

	.modal-content {
		border: 0;
	}

	.modal-dialog-centered {
		min-height: auto;
	}
}
