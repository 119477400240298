/*!
 * bsStepper v{version} (https://github.com/Johann-S/bs-stepper)
 * Copyright 2018 - {year} Johann-S <johann.servoire@gmail.com>
 * Licensed under MIT (https://github.com/Johann-S/bs-stepper/blob/master/LICENSE)
 */

.bs-stepper .step-trigger {
	display: inline-flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding: 20px;
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 0.05rem;
	line-height: 1.5;
	color: #6c757d;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: none;
	border-radius: 0.25rem;
	transition:
		background-color 0.15s ease-out,
		color 0.15s ease-out;
}

.bs-stepper .step-trigger:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.bs-stepper .step-trigger:disabled,
.bs-stepper .step-trigger.disabled {
	pointer-events: none;
	opacity: 0.65;
}

.bs-stepper .step-trigger:focus {
	color: #007bff;
	outline: none;
}

.bs-stepper .step-trigger:hover {
	text-decoration: none;
	background-color: rgba(0, 0, 0, 0.06);
}

.bs-stepper-label {
	display: inline-block;
	margin: 0.25rem;
}

.bs-stepper-header {
	display: flex;
	align-items: center;
}

.bs-stepper-line,
.bs-stepper .line {
	flex: 1 0 32px;
	min-width: 1px;
	min-height: 1px;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.12);
}

.bs-stepper-circle {
	display: inline-flex;
	align-content: center;
	justify-content: center;
	width: 2em;
	height: 2em;
	padding: 0.5em 0;
	margin: 0.25rem;
	line-height: 1em;
	color: #fff;
	background-color: #6c757d;
	border-radius: 1em;
}

.active {
	.bs-stepper-circle {
		background-color: $primary;
	}
	.bs-stepper-label {
		color: $primary;
	}
}

.bs-stepper-content {
	padding: 0 20px 20px;
}

.bs-stepper.vertical {
	display: flex;
}

.bs-stepper.vertical .bs-stepper-header {
	flex-direction: column;
	align-items: stretch;
	margin: 0;
}

.bs-stepper.vertical .bs-stepper-pane,
.bs-stepper.vertical .content {
	display: block;
}

.bs-stepper.vertical .bs-stepper-pane:not(.fade),
.bs-stepper.vertical .content:not(.fade) {
	display: block;
	visibility: hidden;
}

.bs-stepper-pane:not(.fade),
.bs-stepper .content:not(.fade) {
	display: none;
}

.bs-stepper .content.fade,
.bs-stepper-pane.fade {
	visibility: hidden;
	transition-duration: 0.3s;
	transition-property: opacity;
}

.bs-stepper-pane.fade.active,
.bs-stepper .content.fade.active {
	visibility: visible;
	opacity: 1;
}

.bs-stepper-pane.active:not(.fade),
.bs-stepper .content.active:not(.fade) {
	display: block;
	visibility: visible;
}

.bs-stepper-pane.dstepper-block,
.bs-stepper .content.dstepper-block {
	display: block;
}

.bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none,
.bs-stepper:not(.vertical) .content.dstepper-none {
	display: none;
}

.vertical .bs-stepper-pane.fade.dstepper-none,
.vertical .content.fade.dstepper-none {
	visibility: hidden;
}

@media (max-width: 768px) {
	.bs-stepper .step-trigger {
		flex-direction: column;
		padding: 10px;
	}

	.bs-stepper-header {
		margin: 0 -10px;
		text-align: center;
	}
	.bs-stepper-content {
		padding: 0;
	}

	.bs-stepper .line {
		flex: 1 0 9px;
		min-width: 1px;
		min-height: 1px;
		margin: auto;
		background-color: rgba(0, 0, 0, 0.12);
	}
}

@media (max-width: 520px) {
	.bs-stepper .step-trigger {
		flex-direction: column;
		padding: 10px;
	}

	.bs-stepper-header {
		margin: 0 -10px;
		text-align: center;
	}
	.bs-stepper-content {
		padding: 0;
	}
}

@media (max-width: 425px) {
	.bs-stepper-header {
		margin: 0 -10px;
		align-items: normal;
		text-align: center;
	}

	.line {
		display: block;
	}

	.bs-stepper .step-trigger {
		font-size: 0.6rem;
		flex-direction: column;
		padding: 6px;
	}
}

@media (max-width: 400px) {
	.bs-stepper-line,
	.bs-stepper .line {
		flex-basis: 20px;
	}
}

@media (max-width: 320px) {
	.bs-stepper-header {
		margin: 0 -10px;
		align-items: normal;
		text-align: center;
	}

	.line {
		display: none;
	}

	.bs-stepper .step-trigger {
		font-size: 0.6rem;
		flex-direction: column;
		padding: 6px;
	}
}
