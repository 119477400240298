.vic-photo {
	width: 50px;
	height: 50px;
	border: 3px solid white;
}

.chatbot-container {
	background-color: #dbe0e8;
}

.chatbot-widget {
	box-shadow: -8px 5px 15px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: -8px 5px 15px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: -8px 5px 15px rgba(0, 0, 0, 0.3);
	z-index: 15;
}

.chatbot-icon {
	background: #fff;
	width: 35px;
	height: 35px;
	border-radius: 100%;
	display: flex; /* Enables flexbox layout */
	justify-content: center; /* Horizontally centers the content */
	align-items: center; /* Vertically centers the content */
	font-size: 1.25rem;
}

.chatbot-header {
	padding: 30px;
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.1em;
	font-size: 0.8rem;
}

.chatbot-body {
	overflow-y: auto;
	height: calc(100vh - 230px);
	background-color: #fff;
	margin-top: -17px;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	overflow-x: hidden;
}

.chatbot-footer {
	background-color: #eeeeee;
}

.vic-bubble {
	background-color: #e6eaf0;
	border-top-right-radius: 12px;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	overflow-wrap: break-word !important;
	width: fit-content;
}

.user-bubble {
	width: fit-content;
	color: #fff;
	border-radius: 12px;
}

.triangle {
	width: 1em;
	height: 1em;
	background-repeat: no-repeat;
	background-image: linear-gradient(
		45deg,
		#e6eaf0 50%,
		rgba(166, 172, 180, 0) 0%
	);
	margin-top: 15px;
}

.chatbot-close-btn {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	padding: 0;
}

.chatbot-submit-btn {
	width: 45px;
	height: 45px;
}

.bs-stepper .line {
	flex-basis: 10px;
}

.spinner-grow.spinner-grow-xs {
	width: 0.5rem;
	height: 0.5rem;
	vertical-align: 0;
}

/* Custom 320px */
@media (max-width: 320px) {
	.btn-group .btn.btn-primary {
		font-size: 0.35rem;
	}

	.btn-group .btn.btn-outline-dark {
		font-size: 0.35rem;
	}
}

@media (min-width: 816px) {
	.chatbot-header {
		font-size: 0.65rem;
		padding: 15px 15px 25px 15px;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
	}

	.chatbot-widget {
		width: 200px;
		font-size: 0.85rem;
		border-radius: 5px;
		margin: 3rem 1rem;
	}

	.chatbot-container input {
		font-size: 0.85rem;
	}

	.chatbot-body {
		height: calc(100vh - 450px);
		min-height: 300px;
		margin-top: -13px;
	}

	.chatbot-footer {
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
	}

	.chatbot-icon {
		width: 45px;
		height: 35px;
		border-radius: 50%;
		font-size: 1rem;
	}

	.ChatSendButton {
		width: 40px;
		height: 40px;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 992px) {
	.bs-stepper .step-trigger {
		padding: 10px;
	}

	.chatbot-widget {
		width: 285px;
		font-size: 1rem;
		border-radius: 5px;
		margin: 3rem 2rem;
	}

	.chatbot-header {
		padding: 18px 9px 28px 9px;
	}

	.bs-stepper-header {
		margin: 1 0px;
		text-align: center;
		position: relative;
		top: -6px;
	}

	.chatbot-body {
		height: calc(100vh - 350px);
	}

	.chatbot-header {
		font-size: 0.8rem;
	}

	.chatbot-icon {
		width: 35px;
		height: 35px;
		font-size: 1.25rem;
	}

	.chatbot-container input {
		font-size: 1rem;
	}

	.ChatSendButton {
		width: 45px;
		height: 45px;
	}
}
