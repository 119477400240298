body,
html,
#root {
	height: 100%;
}

.content {
	height: 100%;
	display: flex;
	flex-direction: column;
}

p {
	line-height: 1.6;
	font-weight: 300;
	color: $navy-blue;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.fw-700 {
	font-weight: 700;
}

.logo {
	width: 216px;
}

// container sizes

.container-max-sm {
	max-width: 576px;
}

.container-max-md {
	max-width: 768px;
}

.container-max-lg {
	max-width: 1040px;
}

// field sizes

.field-xs {
	max-width: 150px;
}

.field-md {
	max-width: 300px;
}

.italic {
	font-family: "arvo", serif;
}

.h1 {
	font-weight: 100;
	color: $navy-blue;
}

.input-text-group {
	@extend .mb-4;
	.zip {
		width: 110px;
	}
	.form-label {
		letter-spacing: 0.05rem;
	}
}

.btn-primary,
.btn-secondary {
	letter-spacing: 0.05rem;
}

.back-btn.btn-light {
	letter-spacing: 0.05rem;
	font-size: 14px;
	border: 0;
	text-transform: uppercase;
	color: $navy-blue;

	&:disabled {
		background-color: transparent;
	}

	&:hover {
		background-color: rgba($pale-blue, 0.15);
	}
}

.debt-types {
	.card {
		position: relative;
		width: 84px;
		min-height: 87px;
		flex-wrap: wrap;
		align-items: stretch;
		transition: background-color 0.2s;

		&:hover {
			transition: background-color 0.2s;
			background-color: rgba($blue, 0.1);
			cursor: pointer;
		}

		.form-check-input {
			position: absolute;
			top: 10px;
			left: 31px;
		}

		label {
			text-transform: uppercase;
			letter-spacing: 0.05rem;
			font-size: 8px;
			cursor: pointer;
		}

		.form-check,
		.form-check-label {
			padding-left: 0;
		}

		svg {
			margin-bottom: 1rem;
		}
	}
}

.form-group {
	position: relative;
	border-color: $gray-300;
	margin: 2rem 0;

	.form-group-label {
		position: absolute;
		top: -10px;
		left: 30px;
		display: inline-block;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		background: $white;
		width: auto;
		text-transform: uppercase;
		font-size: 0.75rem;
		color: rgba($grey-blue, 0.9);
		letter-spacing: 0.07em;
	}
}

.uppercase-smaller {
	text-transform: uppercase;
	font-size: 0.75rem;
	letter-spacing: 0.05em;
	border-width: 1px;
	text-decoration: none;
}

.input-group
	> :not(:first-child):not(.dropdown-menu):not(.form-floating):not(
		.valid-tooltip
	):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
	border-top-right-radius: $input-border-radius;
	border-bottom-right-radius: $input-border-radius;
}

$btn-group-btn-padding-x: 0.5rem;
$btn-group-btn-padding-y-lg: 0.75rem;
$btn-group-btn-padding-y: 0.4rem;

.btn-group {
	.btn {
		padding-top: $btn-group-btn-padding-y;
		padding-bottom: $btn-group-btn-padding-y;
		padding-left: $btn-group-btn-padding-x;
		padding-right: $btn-group-btn-padding-x;

		@include media-breakpoint-up(lg) {
			padding-top: $btn-group-btn-padding-y-lg;
			padding-bottom: $btn-group-btn-padding-y-lg;
		}

		&.btn-outline-dark,
		&.btn-primary {
			@extend .uppercase-smaller;
		}

		&.btn-outline-dark {
			color: $primary;
		}

		&.btn-primary {
			box-shadow: none;
		}

		&:active.btn-primary,
		&:focus.btn-primary {
			color: $white;
		}
	}

	&:focus.btn-outline-dark,
	:active.btn-outline-dark {
		box-shadow: none;
	}
}

.all-caps-link {
	color: $primary;
	@extend .uppercase-smaller;
	letter-spacing: 0.05em;
	border: none;
}

h1.page-title {
	.icon {
		color: $gray-300;
	}
}

.border-dashed {
	border-style: dashed !important;
}

.navbar .nav-link {
	font-weight: 900;
	font-size: 0.85rem;
	letter-spacing: 0.05rem;
	color: $grey-blue;

	svg {
		color: $pale-blue;
	}
}

.form-nav-buttons {
	margin-top: 5rem;
}

.info-block {
	@extend .rounded;
	background-color: rgba($primary, 0.1);
	padding: 1rem;

	h1 {
		font-weight: 100;
		font-size: 1.3rem;
		color: $blue-800;
	}
}

.modal-header h1 {
	border: 0;
	font-weight: 100;
	font-size: 1.3rem;
	color: $blue-800;
	margin: 0;
	padding: 0;
}

.btn-group .btn.btn-outline-dark {
	font-size: 0.5rem;
}

.btn-group .btn.btn-primary {
	font-size: 0.5rem;
}

.modal-header h1 {
	border: 0;
	font-weight: 100;
	font-size: 1.3rem;
	color: $blue-800;
	margin: 0;
	padding: 0;
}

.btn-link:hover {
	color: darken($primary, 10);
}

.btn:disabled {
	background-color: #dcdcdc;
	color: #4c4c4c;
	border-color: #dcdcdc;
}

.text-primary.btn {
	&:disabled {
		color: #4c4c4c;
		background-color: transparent;
		border-color: transparent;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.text-link {
	@extend .text-primary, .cursor-pointer;
	text-decoration: underline;
}

.ChartDisplay {
	width: 170px;
	height: 170px;
	display: block;
	margin: 0 auto;
}

// Stepper Overrides

.bs-stepper-label {
	font-size: 0.65rem;
}

.step-trigger {
	.bs-stepper-label .step-title,
	.bs-stepper-label .step-count {
		color: rgba($primary, 0.9);
	}

	.bs-stepper-circle {
		background-color: rgba($primary, 0.65);
	}

	&:hover {
		background: rgba($pale-blue, 0.2);
	}
}

.step-trigger:disabled {
	.bs-stepper-label .step-title {
		color: #000000;
	}

	.bs-stepper-label .step-count {
		color: #455162;
	}

	.bs-stepper-circle {
		background-color: #98a5b9;
	}
}

@media (min-width: 520px) {
	.btn-group .btn.btn-outline-dark {
		font-size: 0.7rem;
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
	.bs-stepper-label {
		font-size: 0.75rem;
	}

	.debt-types {
		.card {
			width: 120px;
			min-height: 100px;

			.form-check-input {
				left: 47px;
			}

			label {
				font-size: 12px;
			}
		}
	}

	.btn-group .btn.btn-outline-dark {
		font-size: 0.7rem;
	}

	.btn-group .btn.btn-primary {
		font-size: 0.7rem;
	}
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
	.debt-types {
		.card {
			.form-check-input {
				left: 40px;
			}

			label {
				font-size: 12px;
			}
		}
	}

	$btn-group-btn-padding-x: 0.6rem;
	$btn-group-btn-padding-y-lg: 0.75rem;
	$btn-group-btn-padding-y: 0.4rem;

	.btn-group {
		.btn {
			padding-top: $btn-group-btn-padding-y;
			padding-bottom: $btn-group-btn-padding-y;
			padding-left: $btn-group-btn-padding-x;
			padding-right: $btn-group-btn-padding-x;

			@include media-breakpoint-up(lg) {
				padding-top: $btn-group-btn-padding-y-lg;
				padding-bottom: $btn-group-btn-padding-y-lg;
			}

			&.btn-outline-dark,
			&.btn-primary {
				@extend .uppercase-smaller;
			}

			&.btn-outline-dark {
				color: $primary;
			}

			&.btn-primary {
				box-shadow: none;
			}

			&:active.btn-primary,
			&:focus.btn-primary {
				color: $white;
			}
		}

		&:focus.btn-outline-dark,
		:active.btn-outline-dark {
			box-shadow: none;
		}
	}
}

.form-control.is-invalid,
.form-control:invalid {
	padding-right: 0.75rem !important;
}
