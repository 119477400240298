$border-radius: 0.5rem;

$blue: #287dad;
$success: #4ec310;
$primary: $blue;
$pink: #d63384;
$danger: $pink;
$light: #ffffff;
$navy-blue: #003c73;
$negative: #e0401a;
$pale-blue: #98a5b9;
$grey-blue: #455162;

$enable-negative-margins: true;
$enable-dark-mode: false;

$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger,
	"navy-blue": $navy-blue,
	"light": $light,
	"dark": $dark,
	"negative": $negative,
	"body-custom-bg": #e6eaf0,
	"gray": $gray-600,
);

$font-family-sans-serif: nunito-sans, sans-serif;

$btn-padding-x: 2rem;
$btn-color: white;
$input-padding-y: 0.57rem;
$form-select-padding-y: 0.57rem;

$form-label-margin-bottom: 0.2rem;
$form-label-font-size: 15px;

$form-feedback-icon-invalid-color: $pink;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' stroke='none'></svg>");
$form-feedback-invalid-color: $danger;
$form-validation-states: (
	"valid": (
		"color": $form-feedback-valid-color,
		"icon": $form-feedback-icon-valid,
	),
	"invalid": (
		"color": $form-feedback-invalid-color,
		"icon": $form-feedback-icon-invalid,
	),
);

$input-border-radius: 9px;
$form-check-input-checked-bg-color: $gray-800;
$form-check-input-checked-border-color: $gray-800;
$btn-border-width: 1px;
$input-btn-padding-y: 0.35rem;
$input-btn-padding-x: 0.25rem;

$input-btn-focus-color-opacity: 0.11;
$input-btn-focus-width: 0.1rem;

$input-btn-focus-color: rgba(
	$component-active-bg,
	$input-btn-focus-color-opacity
);
$font-weight-bold: 500;

$font-weight-base: 400;
